import {
  IMAGE_SCALING,
  IMAGE_RATIO,
  BUTTONS_STYLE,
  LIST_IMAGE_LAYOUT,
  LIST_LAYOUT,
  LIST_LOCATION_AND_DATE_FORMAT,
  WIDGET_TYPE,
  LIST_ALIGNMENT,
  MOBILE_LIST_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor'
import {remapColor, remapFont} from '../../commons/custom-css-utils'
import stylesParams, {WidgetStyleParams} from './stylesParams'

export const customCssVars: CustomCssVarsFn<WidgetStyleParams> = ({styleParams, isMobile: mobile, isRTL: RTL}) => {
  const styles = {
    get: param => {
      return (
        styleParams.booleans[param.key] ??
        styleParams.numbers[param.key] ??
        styleParams.colors[param.key] ??
        styleParams.fonts[param.key]
      )
    },
  }

  const get = param => {
    const key = param.key
    const value = styles.get(param)
    const mobileKey = key + 'Mobile'
    const deprecatedMobileValue = styles.get(mobileKey)

    if (mobile) {
      return value ?? deprecatedMobileValue
    }

    return value
  }

  const getListLayout = () => {
    const listLayout = get(stylesParams.listLayout)
    return listLayout === LIST_LAYOUT.GRID ? LIST_LAYOUT.NEW_GRID : listLayout
  }

  const getMobileLayout = () => get(stylesParams.mobileListLayout)

  const isListAdditionalInfoEnabled = () => get(stylesParams.listShowAdditionalInfo)

  const isListFullDateEnabled = () => get(stylesParams.listShowFullDate)

  const isListLocationEnabled = () => get(stylesParams.listShowLocation)

  const isListDescriptionEnabled = () => get(stylesParams.listShowDescription)

  const isListSocialShareEnabled = () => get(stylesParams.listShowSocialShare)

  const isListMembersEnabled = () => get(stylesParams.listShowMembers)

  const isListAdditionalComponentsVisible = () => {
    if (mobile && isMobileCompactLayout()) {
      return false
    }
    return (
      isListAdditionalInfoEnabled() &&
      (isListFullDateEnabled() || isListLocationEnabled() || isListDescriptionEnabled() || isListSocialShareEnabled())
    )
  }

  const isListDateEnabled = () => get(stylesParams.listShowDate)

  const isListVenueNameEnabled = () => get(stylesParams.listShowVenueName)

  const isListFullDateOrLocationEnabled = () => isListFullDateEnabled() || isListLocationEnabled()

  const getListLocationAndDateFormat = () => get(stylesParams.listLocationAndDateFormat)

  const getCardLocationAndDateFormat = () => get(stylesParams.cardLocationAndDateFormat)

  const isHollowButton = (buttonStyle: BUTTONS_STYLE) =>
    [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  const isRoundedButton = (buttonStyle: BUTTONS_STYLE) =>
    [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  const isSingleHollowButton = () => isHollowButton(get(stylesParams.buttonsStyle))

  const isSingleRoundedButton = () => isRoundedButton(get(stylesParams.buttonsStyle))

  const isListMainTitleEnabled = () => get(stylesParams.listShowMainTitle)

  const isListHollowButton = () => isHollowButton(get(stylesParams.listButtonStyle))

  const isListRoundedButton = () => isRoundedButton(get(stylesParams.listButtonStyle))

  const getWidgetType = () => get(stylesParams.widgetType)

  const isMultiEventWidget = () => getWidgetType() === WIDGET_TYPE.LIST

  const isCalendarLayout = () => isMultiEventWidget() && getListLayout() === LIST_LAYOUT.CALENDAR

  const isTodayButtonHollow = () => isHollowButton(get(stylesParams.todayButtonStyle))

  const isTodayButtonRounded = () => isRoundedButton(get(stylesParams.todayButtonStyle))

  const isCardsLayout = () =>
    isMultiEventWidget() && (getListLayout() === LIST_LAYOUT.NEW_GRID || getListLayout() === LIST_LAYOUT.GRID)

  const isListLayout = () => isMultiEventWidget() && getListLayout() === LIST_LAYOUT.LIST

  const isMobileCompactLayout = () => isMultiEventWidget() && getMobileLayout() === MOBILE_LIST_LAYOUT.COMPACT

  const isListImageEnabled = () => get(stylesParams.listShowImage)

  const isListImageLayoutRight = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.RIGHT
  const isListImageLayoutLeft = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.LEFT

  const getSideBySideEvenImageDirection = () => (isListImageLayoutLeft() ? 'row' : 'row-reverse')

  const getSideBySideOddImageDirection = () => (isListImageLayoutRight() ? 'row-reverse' : 'row')

  const isMultiFitImage = () => get(stylesParams.multiImageScaling) === IMAGE_SCALING.FIT

  const isSingleImageEnabled = () => get(stylesParams.showImage)

  const isSingleFitImage = () => get(stylesParams.imageScaling) === IMAGE_SCALING.FIT

  const getMultiImageRatio = () => {
    if (get(stylesParams.multiImageRatio)) {
      return get(stylesParams.multiImageRatio)
    }

    if (isCardsLayout() && get(stylesParams.imageRatio)) {
      return get(stylesParams.imageRatio)
    }

    return getDefaultMultiImageRatio()
  }

  const isMultiImageSquare = () => getMultiImageRatio() === IMAGE_RATIO['1:1']

  const getDefaultMultiImageRatio = () => {
    if (isCalendarLayout() || (mobile && !isCardsLayout())) {
      return IMAGE_RATIO['16:9']
    }

    return IMAGE_RATIO['1:1']
  }

  const getListShortDateLocationAlignment = () => {
    const alignment = get(stylesParams.listAlignment)

    switch (alignment) {
      case LIST_ALIGNMENT.LEFT:
        return RTL ? 'flex-end' : 'flex-start'
      case LIST_ALIGNMENT.CENTER:
        return 'center'
      case LIST_ALIGNMENT.RIGHT:
        return RTL ? 'flex-start' : 'flex-end'
    }
  }

  const getListTextAlignment = () => {
    const alignment = get(stylesParams.listAlignment)

    switch (alignment) {
      case LIST_ALIGNMENT.LEFT:
        return 'left'
      case LIST_ALIGNMENT.CENTER:
        return 'center'
      case LIST_ALIGNMENT.RIGHT:
        return 'right'
    }
  }

  const isListTextAlignmentLeft = () => get(stylesParams.listAlignment) === LIST_ALIGNMENT.LEFT
  const isListTextAlignmentRight = () => get(stylesParams.listAlignment) === LIST_ALIGNMENT.RIGHT

  const getSingleButtonColor = () => (isSingleHollowButton() ? 'hollowButtonsFontColor' : 'fullButtonsFontColor')

  const getSingleOnImageButtonColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? 'onImageHollowButtonsFontColor' : 'onImageFullButtonsFontColor'
    } else {
      return getSingleButtonColor()
    }
  }

  const getSingleButtonBackgroundColor = () => (isSingleHollowButton() ? null : 'buttonsBackgroundColor')

  const getSingleOnImageButtonBackgroundColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? null : 'onImageButtonsBackgroundColor'
    } else {
      return getSingleButtonBackgroundColor()
    }
  }

  const getSingleButtonBorderColor = () => (isSingleHollowButton() ? 'buttonsBorderColor' : null)

  const getSingleOnImageButtonBorderColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? 'onImageButtonsBorderColor' : null
    } else {
      return getSingleButtonBorderColor()
    }
  }

  const getCalendarEventNameFontSize = () =>
    Number(get(stylesParams.calendarCellEventNameFont).size?.replace('px', '')) || 14

  const getEventSpacing = () => {
    if ((mobile && isMobileCompactLayout()) || isListLayout()) {
      return get(stylesParams.listStripWidth)
    } else if (isCardsLayout()) {
      return get(stylesParams.cardMargins)
    } else {
      return get(stylesParams.sideBySideLayoutMargins)
    }
  }

  return {
    CARD_MAX_WIDTH: '500',
    CARD_MIN_WIDTH: '200',
    listMainTitleVisibility: isListMainTitleEnabled() ? 'block' : 'none',
    listFullDateVisibility: isListFullDateEnabled() ? 'block' : 'none',
    listLocationVisibility: isListLocationEnabled() ? 'block' : 'none',
    listVenueNameVisibility: isListVenueNameEnabled() ? 'block' : 'none',
    listDateVisibility: isListDateEnabled() ? 'block' : 'none',
    listDateVisibilityFlex: isListDateEnabled() ? 'flex' : 'none',
    listImageVisibility: isListImageEnabled() ? 'block' : 'none',
    listDescriptionVisibility: isListDescriptionEnabled() ? 'block' : 'none',
    listSocialBarVisibility: isListSocialShareEnabled() ? 'block' : 'none',
    listMembersVisibility: isListMembersEnabled() ? 'block' : 'none',
    listSocialBarVisibilityFlex: isListSocialShareEnabled() ? 'flex' : 'none',
    listShortDateLocationDividerVisibility: !isListDateEnabled() || !isListVenueNameEnabled() ? 'none' : 'initial',
    listShortDateLocationAlignment: getListShortDateLocationAlignment(),
    listShortDateLocationLocationWidth: isListDateEnabled() ? '' : '100%',
    listShortDateLocationDateWidth: isListVenueNameEnabled() ? '80%' : '100%',
    listTextAlignment: getListTextAlignment(),
    listImageAspectRatio: isMultiImageSquare() ? '100' : '56.25',
    listImageIsSquare: isMultiImageSquare() ? '1' : '0',
    listAdditionalInfoVisibility: isListAdditionalComponentsVisible() ? 'block' : 'none',
    listAdditionalInfoVisibilityArrow: isListAdditionalComponentsVisible() ? 'inline-flex' : 'none',
    isListTextAlignmentLeft: isListTextAlignmentLeft() ? '1' : '0',
    isListTextAlignmentRight: isListTextAlignmentRight() ? '1' : '0',
    sideBySideRibbonLeftMargin: isListTextAlignmentLeft() ? '12%' : 'auto',
    sideBySideRibbonRightMargin: isListTextAlignmentRight() ? '12%' : 'auto',
    sideBySideImageDirectionEven: getSideBySideEvenImageDirection(),
    sideBySideImageDirectionOdd: getSideBySideOddImageDirection(),
    sideBySideMinimumItemWidth: isListImageEnabled() ? '480px' : '320px',
    sideBySideTitleBottomMargin:
      (!isListDescriptionEnabled && getListLocationAndDateFormat() !== LIST_LOCATION_AND_DATE_FORMAT.FULL) ||
      !isListFullDateOrLocationEnabled()
        ? '0'
        : '0.5em',
    sideBySideFullDateLocationBottomMargin: !isListDescriptionEnabled ? '0' : '30px',
    sideBySideShortDateLocationVisibility:
      getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT &&
      (isListDateEnabled() || isListVenueNameEnabled())
        ? 'block'
        : 'none',
    sideBySideFullDateLocationVisibility:
      getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.FULL &&
      (isListFullDateEnabled() || isListLocationEnabled())
        ? 'block'
        : 'none',
    cardShortDateLocationVisibility:
      getCardLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT ? 'block' : 'none',
    cardFullDateLocationVisibility:
      getCardLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.FULL ? 'block' : 'none',
    cardHoveredFullDateLocationVisibility:
      isListFullDateOrLocationEnabled() && getCardLocationAndDateFormat() !== LIST_LOCATION_AND_DATE_FORMAT.FULL
        ? 'block'
        : 'none',
    listLayoutTitleLocationVisibility: isListVenueNameEnabled() ? 'block' : 'none',
    listLayoutTitleLocationTitleWidth: isListVenueNameEnabled() ? '90%' : '100%',
    multiContainImageVisibility: isMultiFitImage() ? 'flex' : 'none',
    multiCoverImageVisibility: isMultiFitImage() ? 'none' : 'flex',
    singleContainImageVisibility: isSingleFitImage() ? 'flex' : 'none',
    singleCoverImageVisibility: isSingleFitImage() ? 'none' : 'flex',
    calendarEventTitleLineClamp: getCalendarEventNameFontSize() < 16 ? '2' : '1',
    eventSpacing: getEventSpacing(),

    // SINGLE LAYOUT button
    ...remapFont('singleButtonFont', isSingleHollowButton() ? 'hollowButtonsFont' : 'fullButtonsFont'),
    ...remapColor('singleButtonColor', getSingleButtonColor()),
    ...remapColor('singleOnImageButtonColor', getSingleOnImageButtonColor()),
    ...remapColor('singleButtonBackgroundColor', getSingleButtonBackgroundColor()),
    ...remapColor('singleOnImageButtonBackgroundColor', getSingleOnImageButtonBackgroundColor()),
    ...remapColor('singleButtonBorderColor', getSingleButtonBorderColor()),
    ...remapColor('singleOnImageButtonBorderColor', getSingleOnImageButtonBorderColor()),
    singleButtonBorderWidth: isSingleHollowButton() ? 'var(--buttonsBorderWidth)' : '0',
    singleButtonBorderRadius: isSingleRoundedButton()
      ? 'var(--roundedButtonsBorderRadius)'
      : 'var(--buttonsBorderRadius)',

    // LIST layouts button
    ...remapFont('multiButtonFont', isListHollowButton() ? 'listHollowButtonFont' : 'listFullButtonFont'),
    ...remapColor('multiButtonColor', isListHollowButton() ? 'listHollowButtonFontColor' : 'listFullButtonFontColor'),
    ...remapColor('multiButtonBackgroundColor', isListHollowButton() ? null : 'listButtonBackgroundColor'),
    ...remapColor('multiButtonBorderColor', isListHollowButton() ? 'listButtonBorderColor' : null),
    multiButtonBorderWidth: isListHollowButton() ? 'var(--listButtonBorderWidth)' : '0',
    multiButtonBorderRadius: isListRoundedButton()
      ? 'var(--listRoundedButtonBorderRadius)'
      : 'var(--listButtonBorderRadius)',

    // CALENDAR TODAY button
    ...remapFont('todayButtonResolvedFont', isTodayButtonHollow() ? 'todayHollowButtonFont' : 'todayButtonFont'),
    ...remapColor('todayButtonResolvedColor', isTodayButtonHollow() ? 'todayHollowButtonColor' : 'todayButtonColor'),
    ...remapColor('todayButtonResolvedBackgroundColor', isTodayButtonHollow() ? null : 'todayButtonBackgroundColor'),
    ...remapColor('todayButtonResolvedBorderColor', isTodayButtonHollow() ? 'todayButtonBorderColor' : null),
    todayButtonResolvedBorderWidth: isTodayButtonHollow() ? 'var(--todayButtonBorderWidth)' : '0',
    todayButtonResolvedBorderRadius: isTodayButtonRounded()
      ? 'var(--todayRoundedButtonBorderRadius)'
      : 'var(--todayButtonBorderRadius)',
  }
}

import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventDateInformation} from '../../../../../../../../../commons/selectors/event'
import {
  getDayMonthText,
  getEventsForDay,
  getSelectedDate,
  getSelectedEventId,
} from '../../../../../../selectors/calendar-layout'
import {getEventById} from '../../../../../../selectors/events'
import {getShareFontSize} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {isMembersVisible, isRibbonVisible} from '../../../../../../selectors/settings'
import {MonthlyCalendarEventDetailsOwnProps, MonthlyCalendarEventDetailsRuntimeProps} from './interfaces'
import {MonthlyCalendarEventDetails as MonthlyCalendarEventDetailsPresentation} from './monthly-calendar-event-details'

const mapRuntime = (context: AppProps): MonthlyCalendarEventDetailsRuntimeProps => {
  const {state, actions} = context
  const event = getEventById(state, getSelectedEventId(state))

  const selectedDay = getSelectedDate(state)
  const events = getEventsForDay(context.state, selectedDay)

  return {
    event,
    dayEventCount: events.length,
    dateText: getDayMonthText(state, selectedDay),
    showMembers: isMembersVisible(state, event),
    fullDate: getEventDateInformation(state, event.id).fullDate,
    shareFontSize: parseInt(getShareFontSize(context), 10),
    showRibbon: isRibbonVisible(state, event),
    closeMonthlyCalendarPopup: () => actions.closeMonthlyCalendarPopup(),
    closeMonthlyCalendarEvent: () => actions.closeMonthlyCalendarEvent(),
  }
}

export const MonthlyCalendarEventDetails = connect<
  MonthlyCalendarEventDetailsOwnProps,
  MonthlyCalendarEventDetailsRuntimeProps
>(mapRuntime)(withTranslation()(MonthlyCalendarEventDetailsPresentation))
export * from './interfaces'

import {withTranslation} from '@wix/yoshi-flow-editor'
import {getElementWidth} from '../../../../../commons/utils/dom'
import {getEvents} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SideBySideOwnProps, SideBySideStateProps} from './interfaces'
import {SideBySide as SideBySidePresentation} from './side-by-side'

const mapRuntime = ({state, host: {dimensions}}: AppProps): SideBySideStateProps => ({
  events: getEvents(state),
  width: dimensions.width || getElementWidth(`#${state.component.id}`),
})

export const SideBySide = connect<SideBySideOwnProps, SideBySideStateProps>(mapRuntime)(
  withTranslation()(SideBySidePresentation),
)
export * from './interfaces'

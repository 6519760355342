import React from 'react'
import classNames from 'classnames'
import {useSettings} from '@wix/tpa-settings/react'
import {ExpandArrow} from '../../../../../../../../icons'
import sc from '../../../../classes.scss'
import sd from '../../../common/mobile-layout.scss'
import {useWidgetState} from '../../../../../hooks/state-provider'
import settingsParams from '../../../../../../settingsParams'
import {getComponentConfig, isListLayout} from '../../../../../selectors/settings'
import s from './additional-info-toggle.scss'

export const AdditionalInfoToggle = ({opened, active}: {opened: boolean; active: boolean}) => {
  const {get} = useSettings()
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))

  const classes = classNames(
    s.root,
    sc.textLineHeight,
    sd.eventNameFont,
    listLayout ? s.listContentColor : sd.eventNameColor,
    {[s.activeColor]: active},
  )

  const text = opened ? get(settingsParams.lessInfo) : get(settingsParams.moreInfo)

  return (
    <div className={classes} role="button" aria-expanded={opened}>
      {text}
      <span className={classNames(s.icon, {[s.reversedIcon]: opened})}>
        <ExpandArrow size={14} />
      </span>
    </div>
  )
}

import _ from 'lodash'
import {updateSiteSettings} from '../../../../commons/actions/site-settings'
import {getComponentEventIds} from '../reducers/component'
import {getEventsIds} from '../selectors/events'
import {GetState, State} from '../types/state'
import {updateComponentDraft, updateComponentTexts, updateStyleParams, INJECT_COMPONENT_DRAFT} from './component'
import {reloadEvents} from './events'
import {settingsTabChanged} from './settings-panel'

export const SettingsUpdatedActionType = {
  SETTINGS: 'SETTINGS',
  SITE_SETTINGS: 'SITE_SETTINGS',
  SELECT_EVENT: 'SELECT_EVENT',
  INJECT_COMPONENT_DRAFT: (compId: string) => `INJECT_COMPONENT_DRAFT-${compId}`,
  DISPLAY_EVENT: 'DISPLAY_EVENT',
  HIDE_EVENT: 'HIDE_EVENT',
  HIDE_EVENT_PENDING: 'HIDE_EVENT/pending',
  NAVIGATE_TO_PAGE: 'NAVIGATE_TO_PAGE',
  NAVIGATE_TO_PAGE_PENDING: 'NAVIGATE_TO_PAGE/pending',
  SETTINGS_TAB_CHANGED_PENDING: 'SETTINGS_TAB_CHANGED/pending',
  SETTINGS_TAB_CHANGED: 'SETTINGS_TAB_CHANGED',
  RELOAD_EVENTS: 'RELOAD_EVENTS',
}

interface SettingsUpdateAction {
  type: string
  [key: string]: any
}

export const updateSettings =
  (action: SettingsUpdateAction, compId: string) => async (dispatch: Function, getState: GetState) => {
    switch (action.type) {
      case SettingsUpdatedActionType.SETTINGS:
        debouncedUpdateComponentDraft(getState, action, dispatch)
        dispatch(updateComponentTexts(action.settings.texts))
        break
      case SettingsUpdatedActionType.SITE_SETTINGS:
        dispatch(updateSiteSettings(action.settings))
        break
      case SettingsUpdatedActionType.SELECT_EVENT:
        dispatch(
          updateComponentDraft({
            byEventId: {eventId: [action.eventId]},
          }),
        )
        break
      case SettingsUpdatedActionType.INJECT_COMPONENT_DRAFT(compId):
        if (action.payload) {
          await dispatch({
            type: INJECT_COMPONENT_DRAFT,
            payload: {
              ...action.payload,
            },
          })
        }
        dispatch(reloadEvents())
        break
      case SettingsUpdatedActionType.DISPLAY_EVENT:
        dispatch(updateComponentDraft({byEventId: {eventId: [...getSelectedEvents(getState()), action.eventId]}}))
        break
      case SettingsUpdatedActionType.HIDE_EVENT:
      case SettingsUpdatedActionType.HIDE_EVENT_PENDING:
        const eventId = action.type === SettingsUpdatedActionType.HIDE_EVENT_PENDING ? action.meta.arg : action.payload
        dispatch(
          updateComponentDraft({
            byEventId: {eventId: getSelectedEvents(getState()).filter(id => eventId !== id)},
          }),
        )
        break
      case SettingsUpdatedActionType.SETTINGS_TAB_CHANGED:
      case SettingsUpdatedActionType.SETTINGS_TAB_CHANGED_PENDING:
        const navigationInfo = action.payload
        dispatch(settingsTabChanged(navigationInfo))
        break
      case SettingsUpdatedActionType.RELOAD_EVENTS:
        dispatch(reloadEvents())
        break
      default:
        break
    }
  }

export const updateStyle = (params: any) => async (dispatch: Function, getState) => {
  const layoutChanging = isLayoutChanging(getState(), params)
  await dispatch(updateStyleParams(params))
  if (layoutChanging) {
    dispatch(reloadEvents())
  }
}

export const isLayoutChanging = (state: State, params: any) => {
  const {listLayout, widgetLayout, recurringFilter} = state.component.settings
  const {listLayout: listLayoutNew, widgetLayout: widgetLayoutNew, recurringFilter: recurringFilterNew} = params.numbers

  const isParamChanging = (newValue, oldValue) => newValue !== undefined && oldValue !== newValue

  return (
    isParamChanging(listLayoutNew, listLayout) ||
    isParamChanging(widgetLayoutNew, widgetLayout) ||
    isParamChanging(recurringFilterNew, recurringFilter)
  )
}

const debouncedUpdateComponentDraft = _.debounce((getState: GetState, action, dispatch: Function) => {
  const state = getState()
  const obsoleteKeys = [
    'closedRegistrationMessages',
    'messages',
    'shoutoutTemplateClicked',
    ...Object.keys(state.component.params),
  ]
  const compSettings = _.omit(action.settings, obsoleteKeys)

  dispatch(updateComponentDraft({settings: compSettings}, action.reload))
}, 1000)

const getSelectedEvents = (state: State) =>
  (getComponentEventIds(state) || getEventsIds(state)).filter(id => !id.includes('demo'))

import {useSettings as useYoshiSettings} from '@wix/tpa-settings/react'
import type {ISettingsParam} from '@wix/tpa-settings'

export const useSettings = (componentSettings = {texts: {}}) => {
  const settings = useYoshiSettings()
  const legacyValue = (param: ISettingsParam) => componentSettings.texts[param.key] || componentSettings[param.key]

  const get = (param: ISettingsParam) => {
    const value = settings.get(param)
    return value ?? legacyValue(param)
  }

  const getWithFallback = (param: ISettingsParam, fallbackParam: ISettingsParam) => {
    const value = settings.get(param)
    const fallbackValue = settings.get(fallbackParam)
    return value ?? fallbackValue ?? legacyValue(param) ?? legacyValue(fallbackParam)
  }

  return {
    ...settings,
    get,
    getWithFallback,
  }
}

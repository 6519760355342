import {isDateTbd} from '@wix/wix-events-commons-statics'
import {isSite} from '../../../../../../commons/selectors/environment'
import {getRibbonData} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {isMembersVisible} from '../../../selectors/settings'
import {DesktopItem as DesktopItemPresentation} from './desktop-item'
import {ItemOwnProps, ItemStateProps} from './interfaces'

const mapRuntime = ({state, host: {dimensions}, isRTL}: AppProps, {event}: ItemOwnProps): ItemStateProps => {
  const ribbon = getRibbonData(state, event)

  return {
    showRibbon: ribbon.visible,
    showMembers: isMembersVisible(state, event),
    opened: isItemOpened(state, event.id),
    rtl: isRTL,
    isSite: isSite(state),
    scheduleTbd: isDateTbd(event),
  }
}

export const DesktopItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(DesktopItemPresentation)

export * from './interfaces'

import classNames from 'classnames'
import React from 'react'
import sc from '../../../classes.scss'
import {Divider} from '../../divider'
import s from '../date-location.scss'
import {useSettings} from '../../../../../hooks/use-settings'
import settingsParams from '../../../../../settingsParams'
import {PresentationProps} from '.'

export const Presentation = ({
  text,
  text2,
  titleVisible,
  dividerVisible,
  dividerWidth,
  dividerHeight,
  onImage,
  dataHook,
  settings,
  settingsKey,
}: PresentationProps) => {
  const {get} = useSettings(settings)
  const title = get(settingsParams[settingsKey])

  return (
    <div data-hook={`${dataHook}-container`} className={classNames({[s.noInnerDivider]: !dividerVisible})}>
      {titleVisible && (
        <div
          className={classNames(s.title, sc.textNoMargin, {[s.onImageLabel]: onImage})}
          data-hook={`${dataHook}-title`}
        >
          {title}
        </div>
      )}
      {titleVisible && dividerVisible && (
        <div className={s.innerDivider} data-hook={`${dataHook}-divider`}>
          <Divider width={dividerWidth} height={dividerHeight} />
        </div>
      )}
      <div className={classNames(s.text, sc.textNoMargin, sc.preWrap, {[s.onImageText]: onImage})}>
        <div className={s.value} data-hook={`${dataHook}-text`}>
          {text || ''}
          {text && text2 ? ', ' : ''}
        </div>
        {text2 && (
          <div className={s.value} data-hook={`${dataHook}-additional-text`}>
            {text2}
          </div>
        )}
      </div>
    </div>
  )
}

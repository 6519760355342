import React from 'react'
import {getRibbonData} from '../../../../selectors/events'
import {Image} from '../../common/image'
import {useWidgetState} from '../../../../hooks/state-provider'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Title} from './title'
import {Date} from './date'
import s from './compact-mobile-item.scss'
import {Location} from './location'

export const CompactMobileItem = ({event}: {event: ExtendedEvent}) => {
  const showRibbon = useWidgetState(state => getRibbonData(state, event).visible)

  return (
    <div className={s.root}>
      <div className={s.image}>
        <Image event={event} />
      </div>
      <div className={s.mainSection}>
        {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
        <Title event={event} />
        <Date event={event} />
        <Location event={event} />
        <div className={s.button}>
          <RsvpButton event={event} minHeight={32} minWidth={100} className={s.buttonFont} />
        </div>
      </div>
    </div>
  )
}

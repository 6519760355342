import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventDateInformation} from '../../../../../../../../../../../commons/selectors/event'
import {AppProps} from '../../../../../../../app/interfaces'
import {connect} from '../../../../../../../runtime-context/connect'
import {isMembersVisible, isRibbonVisible} from '../../../../../../../../selectors/settings'
import {MobileMonthlyEventDetailsOwnProps, MobileMonthlyEventDetailsRuntimeProps} from './interfaces'
import {MobileMonthlyEventDetails as MobileMonthlyEventDetailsPresentation} from './mobile-monthly-event-details'

const mapRuntime = (
  {state, actions}: AppProps,
  {event}: MobileMonthlyEventDetailsOwnProps,
): MobileMonthlyEventDetailsRuntimeProps => {
  return {
    hasImage: Boolean(event.mainImage),
    showMembers: isMembersVisible(state, event),
    date: getEventDateInformation(state, event.id).fullDate,
    closeMonthlyCalendarEvent: actions.closeMonthlyCalendarEvent,
    showRibbon: isRibbonVisible(state, event),
  }
}

export const MobileMonthlyEventDetails = connect<
  MobileMonthlyEventDetailsOwnProps,
  MobileMonthlyEventDetailsRuntimeProps
>(mapRuntime)(withTranslation()(MobileMonthlyEventDetailsPresentation))
export * from './interfaces'

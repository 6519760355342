import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {isMembersInstalled, isMembersVisible, isRibbonVisible} from '../../../selectors/settings'
import {SideBySideItemOwnProps, SideBySideItemStateProps} from './interfaces'
import {SideBySideItem} from './side-by-side-item'

const mapRuntime = (
  {state, host: {dimensions}}: AppProps,
  {event}: SideBySideItemOwnProps,
): SideBySideItemStateProps => {
  return {
    showMembers: isMembersVisible(state, event),
    membersEnabled: isMembersInstalled(state),
    showRibbon: isRibbonVisible(state, event),
  }
}

export default connect<SideBySideItemOwnProps, SideBySideItemStateProps>(mapRuntime)(SideBySideItem)
export * from './interfaces'

import {isMobile} from '../../../../../commons/selectors/environment'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {isCardsLayout} from '../../selectors/list-settings'
import {getComponentConfig, isListLayout} from '../../selectors/settings'
import {MainListTitle as Presentation} from './main-list-title'
import {MainListTitleStateProps} from './interfaces'

const mapRuntimeToContext = ({state}: AppProps): MainListTitleStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    listLayout: isListLayout(componentConfig),
    cardsLayout: isCardsLayout(componentConfig),
    mobile: isMobile(state),
    settings: componentConfig.settings,
  }
}

export const MainListTitle = connect<{}, MainListTitleStateProps>(mapRuntimeToContext)(Presentation)
export * from './interfaces'
